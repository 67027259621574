import './form.css';
import React, { useRef, useState } from 'react';
import { Button, Checkbox, Form, Icon } from 'semantic-ui-react';
import emailjs from "emailjs-com";


function ContactForm({ fontColor, fontSize, fontWeight }) {

    const conForm = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState(null);
  

    const sendEmail = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setIsSubmitting(true);
        setError(null);
        setIsSubmitted(false);


        console.log('submit was clicked');
       
    
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, "#contact-form", process.env.REACT_APP_USER_ID)
            .then((result) => {
                console.log(result.text);
                setIsSubmitted(true);
            }, (error) => {
                console.log(error.text);
                setError(error.text);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
          e.target.reset();
      };

    const labelStyle = {
        color: fontColor,
        fontSize: fontSize,
        fontWeight: fontWeight
      };
    

    return (

        <div className='formContainer'>
            {/* <div className='formTitle'>Tell us about yourself</div> */}
            <div className='clientForm'>

            <Form id="contact-form" onSubmit={sendEmail} ref={conForm}>
                <Form.Field required className='inputContainer'>
                    <input className='inputField' placeholder='Name' name='name' required />
                    <label className='inputLabel'>Name</label>
                </Form.Field>
                <Form.Field required className='inputContainer'>
                    <input className='inputField' placeholder='Email' name='user_email' required />
                    <label className='inputLabel'>Email</label>
                </Form.Field>
                <Form.Field required className='inputContainer'>
                    <input className='inputField' placeholder='Phone' name='phone'  required/>
                    <label className='inputLabel'>Phone</label>
                </Form.Field>
                <Form.Field required className='inputContainer'>
                    <input className='inputField' placeholder='Company' name='company' required />
                    <label className='inputLabel'>Company</label>
                </Form.Field>
                <Form.Field required className='inputContainer'>
                    <textarea className='inputTextArea' placeholder='How can we help?' name='message' required></textarea>
                    <label id='test' className='inputTextAreaLabel'>How can we help?</label>
                </Form.Field>
                <Form.Field required className='inputContainer'>
                    <div className='ui checkbox' onClick={(e) => e.stopPropagation()}>
                
                    <input className='checkbox' placeholder='agree' name='agreement' type='checkbox' required />
                    <label style={labelStyle} id='' className='checkboxLabel'>I agree to be contacted</label>
                    </div>
                </Form.Field>
                <Button className='formSubmitButton' type='submit' disabled={isSubmitting || isSubmitted}>
                         {isSubmitting ?
                    <><Icon name="asterisk" loading /> Submitting...</> :
                    isSubmitted ? 'Message sent' : 'Submit'
                    }
                </Button>
                {error && <div>{error}</div>}
            </Form>
            </div>
        </div>
        );
    }
    export default ContactForm;