import React from 'react';
import './workplace.css';
// import Background from '../../assets/workplaceBG5.png';
import BG from '../../assets/workplaceBG1.png';

import { Button, Icon, Modal } from 'semantic-ui-react';
import modernWorkplace from '../../assets/modernWorkplace.svg';
import iconProductivity from '../../assets/productivity.svg';
import iconOffice from '../../assets/officeIcon.svg';
import iconExchange from '../../assets/exchangeIcon.svg';
import iconSharePoint from '../../assets/sharePointIcon.svg';
import iconOneDrive from '../../assets/oneDriveIcon.svg';
import iconTeams from '../../assets/teamsIcon.svg';
import iconDeviceManagement from '../../assets/deviceManagement.svg';
import iconAppAutomation from '../../assets/appAutomation.svg';
import iconDataAnalytics from '../../assets/dataAnalytics.svg';
import iconSecurity from '../../assets/security.svg';
import iconIntune from '../../assets/intune.svg';
import iconWindows from '../../assets/windows.svg';
import iconAndroid from '../../assets/android.svg';
import iconApple from '../../assets/apple.svg';
import iconAutomate from '../../assets/automate.svg';
import iconApps from '../../assets/apps.svg';
import iconPBI from '../../assets/pbi.svg';
import iconAAD from '../../assets/AAD.svg';
import iconAIP from '../../assets/AIP.svg';
import iconAADIP from '../../assets/AADIP.svg';
import iconMSDefender from '../../assets/MSDefender.svg';
import iconConditionalAccess from '../../assets/conditionalAccess.svg';
import iconSentinelOne from '../../assets/sentinelOne.svg';
import ContactForm from '../form/form';


function exampleReducer(state, action) {
    switch (action.type) {
      case 'OPEN_MODAL':
        return { open: true, dimmer: action.dimmer }
      case 'CLOSE_MODAL':
        return { open: false }
      default:
        throw new Error()
    }
  }


function Workplace() {
    const [state, dispatch] = React.useReducer(exampleReducer, {
        open: false,
        dimmer: undefined,
      })
      const { open, dimmer } = state
    return (
        <div id='workplace' className="workplace-container">
            <div className="workplace">  
                <div className='workplaceBGIMG'><img className="" src={BG}  alt="logo" /></div>
                {/* <div className='workplaceBGIMG2'><img className="" src={Background}  alt="logo" /></div> */}
                <div className='workplaceMainSection'>
                        <div className='workplaceMainSectionImg'>
                            <img className='mdimg' src={modernWorkplace} alt="Appimage" />
                        </div>
                        <div className='workplaceMainSectionText'>
                            <div className='workplaceTextCategory'>Solutions</div> 
                            <div className='workplaceTextHeading'>Secured<br></br>Workplace</div> 
                            <div className='workplaceTextSubheading'>End to end secure workplace solutions designed to fit your organizational technology needs.</div>
                            <div>
                                <Button icon  labelPosition='right' onClick={() => dispatch({ type: 'OPEN_MODAL', dimmer: 'blurring' })} className='workplaceMainSectionButton'> 
                                LEARN MORE
                                    <Icon name='long arrow alternate right' />
                                </Button>

                                <Modal 
                                    className='wPModal'
                                    dimmer={dimmer}
                                    open={open}
                                    onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
                                >
                                    {/* <Modal.Header>Modern Workplace</Modal.Header> */}
                                    <Modal.Content>
                                        <div className='workplaceModal'>
                                            <div className='workplaceModalContent'>
                                                <div className='workplaceModalHeader'>
                                                    <div className='workplaceModalHeaderTitle'> Secured Workplace</div>
                                                    <div className='workplaceModalHeaderCloseIcon'>
                                                        <Icon name='window close outline' onClick={() => dispatch({ type: 'CLOSE_MODAL'})}></Icon>
                                                    </div>
                                                </div>
                                                    <div className='workplaceModalContentIcons'>
                                                        <div className='productivity iconsRow'>
                                                            <div><img className="headerIcon" src={iconProductivity}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconOffice}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconExchange}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconSharePoint}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconOneDrive}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconTeams}  alt="logo" /></div>
                                                        </div>
                                                        <div className='deviceManagement iconsRow'> 
                                                            <div><img className="headerIcon" src={iconDeviceManagement}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconIntune}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconWindows}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconAndroid}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconApple}  alt="logo" /></div>
                                                        </div>
                                                        {/* <div className='Automation iconsRow'>
                                                            <div><img className="headerIcon" src={iconAppAutomation}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconAutomate}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconApps}  alt="logo" /></div>
                                                        </div>
                                                        <div className='Automation iconsRow'>
                                                            <div><img className="headerIcon" src={iconDataAnalytics}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconPBI}  alt="logo" /></div>
                                                        </div> */}
                                                        <div className='Automation iconsRow'>
                                                            <div><img className="headerIcon" src={iconSecurity}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconAAD}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconAIP}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconAADIP}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconMSDefender}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconConditionalAccess}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconSentinelOne}  alt="logo" /></div>
                                                        </div>
                                                </div>
                                                <div className='workplaceModalContentText'>Our secure workplace solution covers all layer of your business technology needs. Using wide array of products configured with security as a focus to support all types of workplaces: physical, hybrid and completely remote organizations.</div>
                                            </div>
                                            <div className='workplaceModalFormComponent'>
                                                <ContactForm />
                                            </div>
                                        </div>
                                    </Modal.Content>
                                </Modal>
                            </div>
                        </div>    
                </div>
            </div>
        </div>
    );
}

export default Workplace;
