import React from 'react';
import './security.css';


import { Button, Icon, Modal } from 'semantic-ui-react';

import BG from '../../assets/securityBG2.png';

import securityWhite from '../../assets/securityWhite.svg';
import securityMain from '../../assets/securityMain.svg';



import iconProductivity from '../../assets/productivity.svg';
import iconOffice from '../../assets/officeIcon.svg';
import iconExchange from '../../assets/exchangeIcon.svg';
import iconSharePoint from '../../assets/sharePointIcon.svg';
import iconOneDrive from '../../assets/oneDriveIcon.svg';
import iconTeams from '../../assets/teamsIcon.svg';
import iconDeviceManagement from '../../assets/deviceManagement.svg';
import iconAppAutomation from '../../assets/appAutomation.svg';
import iconDataAnalytics from '../../assets/dataAnalytics.svg';
import iconSecurity from '../../assets/security.svg';
import iconIntune from '../../assets/intune.svg';
import iconWindows from '../../assets/windows.svg';
import iconAndroid from '../../assets/android.svg';
import iconApple from '../../assets/apple.svg';
import iconAutomate from '../../assets/automate.svg';
import iconApps from '../../assets/apps.svg';
import iconPBI from '../../assets/pbi.svg';
import iconAAD from '../../assets/AAD.svg';
import iconAIP from '../../assets/AIP.svg';
import iconAADIP from '../../assets/AADIP.svg';
import iconMSDefender from '../../assets/MSDefender.svg';
import iconConditionalAccess from '../../assets/conditionalAccess.svg';
import iconSentinelOne from '../../assets/sentinelOne.svg';
import ContactForm from '../form/form';


function exampleReducer(state, action) {
    switch (action.type) {
      case 'OPEN_MODAL':
        return { open: true, dimmer: action.dimmer }
      case 'CLOSE_MODAL':
        return { open: false }
      default:
        throw new Error()
    }
  }


function Security() {
    const [state, dispatch] = React.useReducer(exampleReducer, {
        open: false,
        dimmer: undefined,
      })
      const { open, dimmer } = state
    return (
        <div id='security' className="security-container">
            <div className="security">  
                <div className='securityBGIMG'><img className="" src={BG}  alt="logo" /></div>
             
                <div className='securityMainSection'>
                        <div className='securityMainSectionIcon'>
                            <img className='securityMainSectionImg' src={securityWhite} alt="Appimage" />
                            <img className='securityMainSectionImgHover' src={securityMain} alt="Appimage" />
                        </div>
                        <div className='securityMainSectionText'>
                            <div className='securityTextCategory'>Solutions</div> 
                            <div className='securityTextHeading'>Sentient<br></br>Security</div> 
                            <div className='securityTextSubheading'>Get secured on all of technology layers. Start with an assessment on Hardware, Network, Operating System, Application and Data.</div>
                            <div>
                                <Button icon labelPosition='right' onClick={() => dispatch({ type: 'OPEN_MODAL', dimmer: 'blurring' })} className='securityMainSectionButton'> 
                                LEARN MORE
                                    <Icon name='long arrow alternate right' />
                                </Button>
                                

                                <Modal 
                                    className='wPModal'
                                    dimmer={dimmer}
                                    open={open}
                                    onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
                                >
                                   
                                    <Modal.Content>
                                        <div className='securityModal'>
                                            <div className='securityModalContent'>
                                                <div className='securityModalHeader'>
                                                    <div className='securityModalHeaderTitle'> Sentient Security</div>
                                                    <div className='securityModalHeaderCloseIcon'>
                                                        <Icon name='window close outline' onClick={() => dispatch({ type: 'CLOSE_MODAL'})}></Icon>
                                                    </div>
                                                </div>
                                                    <div className='securityModalContentIcons'>            
                                                        <div className='Automation iconsRow'>
                                                            <div><img className="headerIcon" src={iconSecurity}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconAAD}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconAIP}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconAADIP}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconMSDefender}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconConditionalAccess}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconSentinelOne}  alt="logo" /></div>
                                                        </div>
                                                </div>
                                                <div className='securityModalContentText'>Secure all fronts of your organizations technology landscape</div>
                                            </div>
                                            <div className='securityModalFormComponent'>
                                                <ContactForm />
                                            </div>
                                        </div>
                                    </Modal.Content>
                                </Modal>
                            </div>
                        </div>    
                </div>
            </div>
        </div>
    );
}

export default Security;
