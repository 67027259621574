
import './root.css';




import Navbar from './navbar/navbar';
import Splash from './splash/splash';
import Workplace from './workplace/workplace';
import DeviceManagement from './devicemanagement/devicemanagement';
import AppAutomation from './appautomation/appautomation';
import DataAnalytics from './analytics/analytics';
import Security from './security/security';
import Services from './services/services';
import Footer from './footer/footer';


function Root() {


  return (
      <main >
        <div  className="App">
          <Navbar  />
          <Splash  />
          <Workplace />
          <DeviceManagement />
          <AppAutomation />
          <DataAnalytics />
          <Security />
          <Services />
          <Footer />
        </div>
      </main>

  );
}

export default Root;
