import React from 'react';
import './footer.css';
import ContactForm from '../form/form';
import {Link} from 'react-scroll';



import modernWorkplace from '../../assets/modernWorkplace.svg';
import deviceManagementMain from '../../assets/deviceManagementMain.svg';
import appAutomationMain from '../../assets/appAutomationMain.svg';
import dataAnalyticsMain from '../../assets/dataAnalyticsMain.svg';
import securityMain from '../../assets/securityMain.svg';
import designMain from '../../assets/design.svg';
import migrateMain from '../../assets/migrate.svg';
import auditMain from '../../assets/audit.svg';



function modalReducer(state, action) {
    switch (action.type) {
      case 'OPEN_MODAL':
        return { open: true, dimmer: action.dimmer }
      case 'CLOSE_MODAL':
        return { open: false }
      default:
        throw new Error()
    }
  }


function Footer() {
    const [state, dispatch] = React.useReducer(modalReducer, {
        open: false,
        dimmer: undefined,
      })
      const { open, dimmer } = state
    return (
        <div id='footer' className="footer-container">
            <div className="footer">  
           
                <div className='footerMainSection'>
                    <div className='footerContact'>
                        <div className='footerCardTitle'></div>
                        <ContactForm fontColor="white" />
                    </div>
                    <div className='footerServiceSolution'>
                        <div>
                          <div className='footerCardTitle' id='firstCardTitle'>Solutions</div>
                          <div className='footerSolutionsSection'>
                            <Link activeClass="active" to="workplace" spy={true} smooth={true}>
                            <div className='footerSolutionsItem'>
                                <img className='footerSolutionsIcon' src={modernWorkplace} alt="Appimage" /> 
                                <div className='footerSolutionsItemText'>Secured Workplace</div>
                            </div>
                            </Link>

                            <Link activeClass="active" to="devicemanagement" spy={true} smooth={true}>
                            <div className='footerSolutionsItem'>
                              <img className='footerSolutionsIcon' src={deviceManagementMain} alt="Appimage" /> 
                              <div className='footerSolutionsItemText'>Device Management</div>
                            </div>
                            </Link>

                            <Link activeClass="active" to="appautomation" spy={true} smooth={true}>
                            <div className='footerSolutionsItem'>
                                <img className='footerSolutionsIcon' src={appAutomationMain} alt="Appimage" /> 
                                <div className='footerSolutionsItemText'>App & Automation</div>
                            </div>
                            </Link>

                            <Link activeClass="active" to="analytics" spy={true} smooth={true}>
                            <div className='footerSolutionsItem'>
                                <img className='footerSolutionsIcon' src={dataAnalyticsMain} alt="Appimage" /> 
                                <div className='footerSolutionsItemText'>Data Analytics</div>
                            </div>
                            </Link>

                            <Link activeClass="active" to="security" spy={true} smooth={true}>
                            <div className='footerSolutionsItem'>
                                <img className='footerSolutionsIcon' src={securityMain} alt="Appimage" /> 
                                <div className='footerSolutionsItemText'>Sentient Security</div>
                            </div>
                            </Link>
                          </div>
                          
                        </div>
                        <div>
                          <div className='footerCardTitle'>Services</div>
                          <div className='footerServicesSection'>
                            <Link activeClass="active" to="services" spy={true} smooth={true}>
                            <div className='footerServicesItem'>
                                <img className='footerServicesIcon' src={designMain} alt="Appimage" /> 
                                <div className='footerServicesItemText'>Design</div>
                            </div>
                            </Link>
                            <Link activeClass="active" to="services" spy={true} smooth={true}>
                            <div className='footerServicesItem'>
                                <img className='footerServicesIcon' src={migrateMain} alt="Appimage" /> 
                                <div className='footerServicesItemText'>Migrate</div>
                            </div>
                            </Link>
                            <Link activeClass="active" to="services" spy={true} smooth={true}>
                            <div className='footerServicesItem'>
                                <img className='footerServicesIcon' src={auditMain} alt="Appimage" /> 
                                <div className='footerServicesItemText'>Audit</div>
                            </div>
                            </Link>
                          </div>
                        </div>
                    </div>
                    
                </div>       
            </div>
        </div>
    );
}

export default Footer;
