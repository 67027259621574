import './splash.css';
import Background from '../../assets/splashBG.png';
import { Icon } from 'semantic-ui-react';
import {Link} from 'react-scroll';

// import { LocomotiveScrollProvider  } from 'react-locomotive-scroll'
// import { useRef } from "react";


function Splash() {
    // const ref = useRef(null);

    // const options = {
    //   smooth: true,
    // }
    return (
    //     <LocomotiveScrollProvider options={options} containerRef={ref}>
    //   <main data-scroll-container ref={ref}>
   
        <div className="splash-container">
            <div className="splash">  
                <div className='splashIMG'>
                    <img className="splashIMGIMG" src={Background}  alt="logo" />
                    <div container className='splashText'>
                        <div className='splashTextHeading'>TECHNOLOGY SIMPLIFIED</div> 
                        <div className='splashTextSubheading'>Great business outcomes<br></br>with the help of technology</div>
                    </div>
                    <Link activeClass="active" to="workplace" spy={true} smooth={true}>
                    <div className='splashPageDownArrow bounce'><Icon name='arrow down' size='large'> </Icon></div>
                    </Link>
                </div>
                
            </div>
        </div>

    );
}

export default Splash;
