import './navbar.css';
import { Button, Grid, Icon, Dropdown } from 'semantic-ui-react';
import React, { useState } from 'react';
import ContactForm from '../form/form';
import MobileNav from '../mobileNav/mobileNav';
import {Link} from 'react-scroll';


import logo from '../../assets/nimbuslogowhite.svg';
import logoN from '../../assets/Nimbus_N.svg';
import modernWorkplace from '../../assets/modernWorkplace.svg';
import deviceManagementMain from '../../assets/deviceManagementMain.svg';
import appAutomationMain from '../../assets/appAutomationMain.svg';
import dataAnalyticsMain from '../../assets/dataAnalyticsMain.svg';
import securityMain from '../../assets/securityMain.svg';
import designMain from '../../assets/design.svg';
import migrateMain from '../../assets/migrate.svg';
import auditMain from '../../assets/audit.svg';



function Navbar() {
    const [scrollState, setScrollState] = useState(false);
    const changeNavbarBG =()=>{
        const scrollValue=document.documentElement.scrollTop;
        if (scrollValue>5200) {
            setScrollState(true);
            console.log (scrollValue)
        }
        else {
            setScrollState(false);
        }
    };
    window.addEventListener('scroll',changeNavbarBG)
    const [dropdownOpen, setDropdownOpen] = useState(false);

  function handleDropdownClose() {
    setDropdownOpen(false);
  }
    
    return (  
        <div className="navbar-container">
            <header>
                    <div className="navbar"> 
                        <Grid className="nav" verticalAlign='middle' columns={3}>
                            <Grid.Row className="NavRow">
                                <Grid.Column width={4}>
                                    <div className='navLogo'><img className="navLogoImg" src={logo}  alt="logo" /></div>
                                    <div className='navLogoMobile'><img className="navLogoMobileImg" src={logoN}  alt="logo" /></div>
                                </Grid.Column>
                                <Grid.Column verticalAlign='middle' width={8}>
                                    <Dropdown floating className={scrollState?"dropdownNavScroll":"dropdownNav"} icon='' text='Solutions' >
                                        <Dropdown.Menu  className="solutionsDropDown" onClick={e => e.stopPropagation()}>
                                            <div className='categoriesSolutions'>
                                            {/* className={scrollState?"navScroll":"nav"} */}
                                                <Link activeClass="active" to="workplace" spy={true} smooth={true}>
                                                    <div className='navSolutionsDropDownItem'>
                                                        <img className='navSolutionsDropDownIcon' src={modernWorkplace} alt="Appimage" /> 
                                                        <div>Secured Workplace</div>
                                                    </div>
                                                </Link>
                                                <Link activeClass="active" to="devicemanagement" spy={true} smooth={true}>
                                                    <div className='navSolutionsDropDownItem'>
                                                        <img className='navSolutionsDropDownIcon' src={deviceManagementMain} alt="Appimage" /> 
                                                        <div>Device Management</div>
                                                    </div>
                                                </Link>
                                                <Link activeClass="active" to="appautomation" spy={true} smooth={true}>
                                                    <div className='navSolutionsDropDownItem'>
                                                        <img className='navSolutionsDropDownIcon' src={appAutomationMain} alt="Appimage" /> 
                                                        <div>App & Automation</div>
                                                    </div>
                                                </Link>
                                                <Link activeClass="active" to="analytics" spy={true} smooth={true}>
                                                    <div className='navSolutionsDropDownItem'>
                                                        <img className='navSolutionsDropDownIcon' src={dataAnalyticsMain} alt="Appimage" /> 
                                                        <div>Data Analytics</div>
                                                    </div>
                                                </Link>
                                                <Link activeClass="active" to="security" spy={true} smooth={true}>
                                                    <div className='navSolutionsDropDownItem'>
                                                        <img className='navSolutionsDropDownIcon' src={securityMain} alt="Appimage" /> 
                                                        <div>Sentient Security</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown floating className={scrollState?"dropdownNavScroll":"dropdownNav"} icon='' direction='left' text='Services' onClick={e => e.stopPropagation()}>
                                        <Dropdown.Menu  className="servicesDropDown" onClick={e => e.stopPropagation()}>
                                        <div className='categoriesServices'>
                                            <Link activeClass="active" to="services" spy={true} smooth={true}>
                                                <div className='navServicesDropDownItem'>
                                                    <img className='navServicesDropDownIcon' src={designMain} alt="Appimage" /> 
                                                    <div>Design</div>
                                                </div>
                                            </Link>
                                            <Link activeClass="active" to="services" spy={true} smooth={true}>
                                                <div className='navServicesDropDownItem'>
                                                    <img className='navServicesDropDownIcon' src={migrateMain} alt="Appimage" /> 
                                                    <div>Migrate</div>
                                                </div>
                                             </Link>
                                             <Link activeClass="active" to="services" spy={true} smooth={true}>
                                                <div className='navServicesDropDownItem'>
                                                    <img className='navServicesDropDownIcon' src={auditMain} alt="Appimage" /> 
                                                    <div>Audit</div>
                                                </div>
                                             </Link>
                                            </div>
                        
                                        </Dropdown.Menu>
                                    </Dropdown>
                          
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <div className="contactButton" onClick={e => e.stopPropagation()}>
                                        <Dropdown floating className={scrollState?"dropdownNavScroll":"dropdownNav"} icon='' direction='left' text='Contact' onClick={e => e.stopPropagation()}>
                                        <Dropdown.Menu  className="contactDropDown" onClick={e => e.stopPropagation()}>
                                            <ContactForm fontColor="white" />
                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="contactButtonMobile" onClick={e => e.stopPropagation()}>
                                        <Dropdown 
                                        className='contactButtonMobileIcon' 
                                        icon='sidebar' 
                                        text='' 
                                        open={dropdownOpen}
                                        onClose={() => setDropdownOpen(false)}
                                        onOpen={() => setDropdownOpen(true)}
                                        >
                                            
                                        <Dropdown.Menu className="contactDropDownMobile" onClick={e => e.stopPropagation()}>
                                            <MobileNav onCloseDropdown={handleDropdownClose} />
                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid> 
                    </div>
            </header>
        </div>
    );
 }




export default Navbar;
