import React from 'react';
import './services.css';


import { Button, Icon, Modal } from 'semantic-ui-react';

import BG from '../../assets/servicesBG4.png';

import designIcon from '../../assets/design.svg';
import discoverIcon from '../../assets/discover.svg';
import buildIcon from '../../assets/build.svg';
import analyzeIcon from '../../assets/analyze.svg';
import transformIcon from '../../assets/transform.svg';
import migrateIcon from '../../assets/migrate.svg';
import auditIcon from '../../assets/audit.svg';
import securityIcon from '../../assets/auditSecurity.svg';
import complianceIcon from '../../assets/auditCompliance.svg';



function modalReducer(state, action) {
    switch (action.type) {
      case 'OPEN_MODAL':
        return { open: true, dimmer: action.dimmer }
      case 'CLOSE_MODAL':
        return { open: false }
      default:
        throw new Error()
    }
  }


function Services() {
    const [state, dispatch] = React.useReducer(modalReducer, {
        open: false,
        dimmer: undefined,
      })
      const { open, dimmer } = state
    return (
        <div id='services' className="services-container">
            <div className="services">  
                {/* <div className='servicesBGIMG'><img className="" src={BG}  alt="logo" /></div> */}
                <div className='servicesMainSection'>
                    <div className='servicesCard'>

                      <div className='servicesCardTitle'>Design</div>
                      <div className='servicesCardBody'>
                          <div className='servicesCardDescription'>Design and deploy business applications and services on the cloud, on-premesis or in hybrid infrastructure.</div>
                          <div className='servicesCardSubHeading'>Process</div>
                          <div className='servicesCardProcessSection'>
                            <div className='servicesCardProcessSectionItem'> 
                              <div className='servicesCardProcessIMG'><img className='' src={discoverIcon} alt="" /></div>
                              <div className='servicesCardProcessTitle'>Discover</div>
                            </div>
                            <div className='servicesCardProcessSectionItem'> 
                              <div className='servicesCardProcessIMG'><img className='' src={designIcon} alt="" /></div>
                              <div className='servicesCardProcessTitle'>Architect</div>
                            </div>
                            <div className='servicesCardProcessSectionItem'> 
                              <div className='servicesCardProcessIMG'><img className='' src={buildIcon} alt="" /></div>
                              <div className='servicesCardProcessTitle'>Build</div>
                            </div>
                          </div>
                          <div className='servicesCardLearnMore'><Icon name='long arrow alternate right'></Icon></div>
                        </div>
                    </div>

                    <div className='servicesCard'>
                      <div className='servicesCardTitle'>Migrate</div>
                        <div className='servicesCardBody'>
                            <div className='servicesCardDescription'>Move to future state systems with ease with every aspect of the transition handled smoothly</div>
                            <div className='servicesCardSubHeading'>Process</div>
                            <div className='servicesCardProcessSection'>
                              <div className='servicesCardProcessSectionItem'> 
                                <div className='servicesCardProcessIMG'><img className='' src={analyzeIcon} alt="" /></div>
                                <div className='servicesCardProcessTitle'>Analyze</div>
                              </div>
                              <div className='servicesCardProcessSectionItem'> 
                                <div className='servicesCardProcessIMG'><img className='' src={transformIcon} alt="" /></div>
                                <div className='servicesCardProcessTitle'>Transform</div>
                              </div>
                              <div className='servicesCardProcessSectionItem'> 
                                <div className='servicesCardProcessIMG'><img className='' src={migrateIcon} alt="" /></div>
                                <div className='servicesCardProcessTitle'>Move</div>
                              </div>
                            </div>
                            <div className='servicesCardLearnMore'><Icon name='long arrow alternate right'></Icon></div>
                          </div>
                     


                    
                    </div>

                    <div className='servicesCard'>
                      <div className='servicesCardTitle'>Audit</div>
                          <div className='servicesCardBody'>
                              <div className='servicesCardDescription'>Meet regulatory and industry compliance and security requirements through deep discovery of assets and implement counter controls</div>
                              <div className='servicesCardSubHeading'>Process</div>
                              <div className='servicesCardProcessSection'>
                                <div className='servicesCardProcessSectionItem'> 
                                  <div className='servicesCardProcessIMG'><img className='' src={auditIcon} alt="" /></div>
                                  <div className='servicesCardProcessTitle'>Audit</div>
                                </div>
                                <div className='servicesCardProcessSectionItem'> 
                                  <div className='servicesCardProcessIMG'><img className='' src={securityIcon} alt="" /></div>
                                  <div className='servicesCardProcessTitle'>Security</div>
                                </div>
                                <div className='servicesCardProcessSectionItem'> 
                                  <div className='servicesCardProcessIMG'><img className='' src={complianceIcon} alt="" /></div>
                                  <div className='servicesCardProcessTitle'>Compliance</div>
                                </div>
                              </div>
                              <div className='servicesCardLearnMore'><Icon name='long arrow alternate right'></Icon></div>
                            </div>
                    
                    
                    


                    </div>

                </div>       
            </div>
        </div>
    );
}

export default Services;
