import './mobileNav.css';
import React, { useRef, useState, Component } from 'react';
import { Button, Form, Icon, Accordion } from 'semantic-ui-react';
import ContactForm from '../form/form';
import {Link} from 'react-scroll';
import { Dropdown } from 'semantic-ui-react';


import modernWorkplace from '../../assets/modernWorkplace.svg';
import deviceManagementMain from '../../assets/deviceManagementMain.svg';
import appAutomationMain from '../../assets/appAutomationMain.svg';
import dataAnalyticsMain from '../../assets/dataAnalyticsMain.svg';
import securityMain from '../../assets/securityMain.svg';
import designMain from '../../assets/design.svg';
import migrateMain from '../../assets/migrate.svg';
import auditMain from '../../assets/audit.svg';



function MobileNav(props) {
  function handleLinkClick() {
    props.onCloseDropdown();
  }


const accordianContent = [
    {
      title: 'Solutions',
      content: [
                <div className='mobileNavCategoriesSolutions'>
                  <Link activeClass="active" to="workplace" spy={true} smooth={true}>
                    <div className='mobileNavSolutionsItem' onClick={handleLinkClick}>
                        <img className='mobileNavSolutionsIcon' src={modernWorkplace} alt="Appimage" /> 
                        <div className='mobileNavSolutionsItemText'>Secured Workplace</div>
                    </div>
                  </Link>
                  <Link activeClass="active" to="devicemanagement" spy={true} smooth={true}>
                    <div className='mobileNavSolutionsItem' onClick={handleLinkClick}>
                        <img className='mobileNavSolutionsIcon' src={deviceManagementMain} alt="Appimage" /> 
                        <div className='mobileNavSolutionsItemText'>Device Management</div>
                    </div>
                    </Link>
                    <Link activeClass="active" to="appautomation" spy={true} smooth={true}>
                    <div className='mobileNavSolutionsItem' onClick={handleLinkClick}>
                        <img className='mobileNavSolutionsIcon' src={appAutomationMain} alt="Appimage" /> 
                        <div className='mobileNavSolutionsItemText'>App & Automation</div>
                    </div>
                    </Link>
                    <Link activeClass="active" to="analytics" spy={true} smooth={true}>
                    <div className='mobileNavSolutionsItem' onClick={handleLinkClick}>
                        <img className='mobileNavSolutionsIcon' src={dataAnalyticsMain} alt="Appimage" /> 
                        <div className='mobileNavSolutionsItemText'>Data Analytics</div>
                    </div>
                    </Link>
                    <Link activeClass="active" to="security" spy={true} smooth={true}>
                    <div className='mobileNavSolutionsItem' onClick={handleLinkClick}>
                        <img className='mobileNavSolutionsIcon' src={securityMain} alt="Appimage" /> 
                        <div className='mobileNavSolutionsItemText'>Sentient Security</div>
                    </div>
                    </Link>
                </div>
      ]
    },
    {
      title: 'Services',
      content: [
                <div className='mobileNavCategoriesServices'>
                  <Link activeClass="active" to="services" spy={true} smooth={true}>
                     <div className='mobileNavServicesItem' onClick={handleLinkClick}>
                        <img className='mobileNavServicesIcon' src={designMain} alt="Appimage" /> 
                        <div>Design</div>
                    </div>
                    </Link>
                    <Link activeClass="active" to="services" spy={true} smooth={true}>
                    <div className='mobileNavServicesItem' onClick={handleLinkClick}>
                        <img className='mobileNavServicesIcon' src={migrateMain} alt="Appimage" /> 
                        <div>Migrate</div>
                    </div>
                    </Link>
                    <Link activeClass="active" to="services" spy={true} smooth={true}>
                    <div className='mobileNavServicesItem' onClick={handleLinkClick}>
                        <img className='mobileNavServicesIcon' src={auditMain} alt="Appimage" /> 
                        <div>Audit</div>
                    </div>
                    </Link>
                </div>
      ]
    },
    {
      title: 'Contact',
      content: [
        <div className='mobileNavForm'><ContactForm fontColor="white" /></div>
      ]
    },
  ]
  
     
    return (
    <div className='mobileNavContainer'>
    <Accordion fluid styled className='mobileNavAccordian' defaultActiveIndex={0} panels={accordianContent} />
    </div>
  );
};


  export default MobileNav;