import React from 'react';
import './appautomation.css';
// import Background from '../../assets/appautomationBG5.png';

import { Button, Icon, Modal } from 'semantic-ui-react';

import BG from '../../assets/2-3.png';

import appAutomationWhite from '../../assets/appAutomationWhite.svg';

import appAutomationMain from '../../assets/appAutomationMain.svg';
// import modernappautomation from '../../assets/modernappautomation.svg';


import iconProductivity from '../../assets/productivity.svg';
import iconOffice from '../../assets/officeIcon.svg';
import iconExchange from '../../assets/exchangeIcon.svg';
import iconSharePoint from '../../assets/sharePointIcon.svg';
import iconOneDrive from '../../assets/oneDriveIcon.svg';
import iconTeams from '../../assets/teamsIcon.svg';
import iconDeviceManagement from '../../assets/deviceManagement.svg';
import iconAppAutomation from '../../assets/appAutomation.svg';
import iconDataAnalytics from '../../assets/dataAnalytics.svg';
import iconSecurity from '../../assets/security.svg';
import iconIntune from '../../assets/intune.svg';
import iconWindows from '../../assets/windows.svg';
import iconAndroid from '../../assets/android.svg';
import iconApple from '../../assets/apple.svg';
import iconAutomate from '../../assets/automate.svg';
import iconApps from '../../assets/apps.svg';
import iconPBI from '../../assets/pbi.svg';
import iconAAD from '../../assets/AAD.svg';
import iconAIP from '../../assets/AIP.svg';
import iconAADIP from '../../assets/AADIP.svg';
import iconMSDefender from '../../assets/MSDefender.svg';
import iconConditionalAccess from '../../assets/conditionalAccess.svg';
import iconSentinelOne from '../../assets/sentinelOne.svg';
import ContactForm from '../form/form';


function exampleReducer(state, action) {
    switch (action.type) {
      case 'OPEN_MODAL':
        return { open: true, dimmer: action.dimmer }
      case 'CLOSE_MODAL':
        return { open: false }
      default:
        throw new Error()
    }
  }


function AppAutomation() {
    const [state, dispatch] = React.useReducer(exampleReducer, {
        open: false,
        dimmer: undefined,
      })
      const { open, dimmer } = state
    return (
        <div id='appautomation' className="appautomation-container">
            <div className="appautomation">  
                <div className='appautomationBGIMG'><img className="" src={BG}  alt="logo" /></div>
             
                <div className='appautomationMainSection'>
                        <div className='appautomationMainSectionIcon'>
                            <img className='appautomationMainSectionImg' src={appAutomationWhite} alt="Appimage" />
                            <img className='appautomationMainSectionImgHover' src={appAutomationMain} alt="Appimage" />
                        </div>
                        <div className='appautomationMainSectionText'>
                            <div className='appautomationTextCategory'>Solutions</div> 
                            <div className='appautomationTextHeading'>Apps &<br></br>Automation</div> 
                            <div className='appautomationTextSubheading'>Develop apps swiftly and with low cost, reduce human errors and ceritfy information as it moves through your organization.</div>
                            <div>
                                <Button icon labelPosition='right' onClick={() => dispatch({ type: 'OPEN_MODAL', dimmer: 'blurring' })} className='appautomationMainSectionButton'> 
                                LEARN MORE
                                    <Icon name='long arrow alternate right' />
                                </Button>

                                <Modal 
                                    className='wPModal'
                                    dimmer={dimmer}
                                    open={open}
                                    onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
                                >
                                   
                                    <Modal.Content>
                                        <div className='appautomationModal'>
                                            <div className='appautomationModalContent'>
                                                <div className='appautomationModalHeader'>
                                                    <div className='appautomationModalHeaderTitle'> Apps & Automation</div>
                                                    <div className='appautomationModalHeaderCloseIcon'>
                                                        <Icon name='window close outline' onClick={() => dispatch({ type: 'CLOSE_MODAL'})}></Icon>
                                                    </div>
                                                </div>
                                                    <div className='appautomationModalContentIcons'>
                                                        <div className='Automation iconsRow'>
                                                            <div><img className="headerIcon" src={iconAppAutomation}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconAutomate}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconApps}  alt="logo" /></div>
                                                        </div>
                                                        
                                                </div>
                                                <div className='appautomationModalContentText'>Our most comprehensive offering that includes wide array of products configured with security in mind to support all types of appautomations: physical, hybrid and complete remote organizations.</div>
                                            </div>
                                            <div className='appautomationModalFormComponent'>
                                                <ContactForm />
                                            </div>
                                        </div>
                                    </Modal.Content>
                                </Modal>
                            </div>
                        </div>    
                </div>
            </div>
        </div>
    );
}

export default AppAutomation;
