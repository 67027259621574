import React from 'react';
import './analytics.css';


import { Button, Icon, Modal } from 'semantic-ui-react';

import BG from '../../assets/analyticsBG.png';

import analyticsWhite from '../../assets/analyticsWhite.svg';
import analyticsMain from '../../assets/analyticsMain.svg';



import iconProductivity from '../../assets/productivity.svg';
import iconOffice from '../../assets/officeIcon.svg';
import iconExchange from '../../assets/exchangeIcon.svg';
import iconSharePoint from '../../assets/sharePointIcon.svg';
import iconOneDrive from '../../assets/oneDriveIcon.svg';
import iconTeams from '../../assets/teamsIcon.svg';
import iconDeviceManagement from '../../assets/deviceManagement.svg';
import iconAppAutomation from '../../assets/appAutomation.svg';
import iconDataAnalytics from '../../assets/dataAnalytics.svg';
import iconSecurity from '../../assets/security.svg';
import iconIntune from '../../assets/intune.svg';
import iconWindows from '../../assets/windows.svg';
import iconAndroid from '../../assets/android.svg';
import iconApple from '../../assets/apple.svg';
import iconAutomate from '../../assets/automate.svg';
import iconApps from '../../assets/apps.svg';
import iconPBI from '../../assets/pbi.svg';
import iconAAD from '../../assets/AAD.svg';
import iconAIP from '../../assets/AIP.svg';
import iconAADIP from '../../assets/AADIP.svg';
import iconMSDefender from '../../assets/MSDefender.svg';
import iconConditionalAccess from '../../assets/conditionalAccess.svg';
import iconSentinelOne from '../../assets/sentinelOne.svg';
import ContactForm from '../form/form';


function exampleReducer(state, action) {
    switch (action.type) {
      case 'OPEN_MODAL':
        return { open: true, dimmer: action.dimmer }
      case 'CLOSE_MODAL':
        return { open: false }
      default:
        throw new Error()
    }
  }


function DataAnalytics() {
    const [state, dispatch] = React.useReducer(exampleReducer, {
        open: false,
        dimmer: undefined,
      })
      const { open, dimmer } = state
    return (
        <div id='analytics' className="analytics-container">
            <div className="analytics">  
                <div className='analyticsBGIMG'><img className="" src={BG}  alt="logo" /></div>
             
                <div className='analyticsMainSection'>
                        <div className='analyticsMainSectionIcon'>
                            <img className='analyticsMainSectionImg' src={analyticsWhite} alt="Appimage" />
                            <img className='analyticsMainSectionImgHover' src={analyticsMain} alt="Appimage" />
                        </div>
                        <div className='analyticsMainSectionText'>
                            <div className='analyticsTextCategory'>Solutions</div> 
                            <div className='analyticsTextHeading'>Data<br></br>Analytics</div> 
                            <div className='analyticsTextSubheading'>Understand your business better with data. Bring your critical business insights to forefront and propell your business with actionable insights</div>
                            <div>
                                <Button icon labelPosition='right' onClick={() => dispatch({ type: 'OPEN_MODAL', dimmer: 'blurring' })} className='analyticsMainSectionButton'> 
                                LEARN MORE
                                    <Icon name='long arrow alternate right' />
                                </Button>
                                

                                <Modal 
                                    className='wPModal'
                                    dimmer={dimmer}
                                    open={open}
                                    onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
                                >
                                   
                                    <Modal.Content>
                                        <div className='analyticsModal'>
                                            <div className='analyticsModalContent'>
                                                <div className='analyticsModalHeader'>
                                                    <div className='analyticsModalHeaderTitle'> Data Analytics</div>
                                                    <div className='analyticsModalHeaderCloseIcon'>
                                                        <Icon name='window close outline' onClick={() => dispatch({ type: 'CLOSE_MODAL'})}></Icon>
                                                    </div>
                                                </div>
                                                    <div className='analyticsModalContentIcons'>
                                                        
                                                        <div className='Automation iconsRow'>
                                                            <div><img className="headerIcon" src={iconDataAnalytics}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconPBI}  alt="logo" /></div>
                                                        </div>
                                                        
                                                </div>
                                                <div className='analyticsModalContentText'>Bring actionable insights to business and department unit leads</div>
                                            </div>
                                            <div className='analyticsModalFormComponent'>
                                                <ContactForm />
                                            </div>
                                        </div>
                                    </Modal.Content>
                                </Modal>
                            </div>
                        </div>    
                </div>
            </div>
        </div>
    );
}

export default DataAnalytics;
