import './devicemanagement.css';
import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import ContactForm from '../form/form';

// BG IMG

import Map from '../../assets/bgDM3.5.png';

// ICONS


import deviceManagementWhite from '../../assets/deviceManagementWhite.svg';
import deviceManagementMain from '../../assets/deviceManagementMain.svg';

import iconDeviceManagement from '../../assets/deviceManagement.svg';

import iconIntune from '../../assets/intune.svg';
import iconWindows from '../../assets/windows.svg';
import iconAndroid from '../../assets/android.svg';
import iconApple from '../../assets/apple.svg';




function exampleReducer(state, action) {
    switch (action.type) {
      case 'OPEN_MODAL':
        return { open:true, dimmer: action.dimmer }
      case 'CLOSE_MODAL':
        return { open: false }
      default:
        throw new Error()
    }
  }


function DeviceManagement() {
    const [state, dispatch] = React.useReducer(exampleReducer, {
        open: false,
        dimmer: undefined,
      })
      const { open, dimmer } = state
  
    return (
        <div id='devicemanagement' className="devicemanagement-container">
            <div className="devicemanagement">  
                <div className='devicemanagementBGIMG'><img className="" src={Map}  alt="logo" /></div>
                <div className='devicemanagementMainSection'>
                        <div className='devicemanagementMainSectionIcon'>
                            <img className='devicemanagementMainSectionImg' src={deviceManagementWhite} alt="Appimage" />
                            <img className='devicemanagementMainSectionImgHover' src={deviceManagementMain} alt="Appimage" />
                        </div>
                        <div className='devicemanagementMainSectionText'>
                            <div className='devicemanagementTextCategory'>Solutions</div> 
                            <div className='devicemanagementTextHeading'>Device<br></br>Management</div> 
                            <div className='devicemanagementTextSubheading'>Manage and protect all your organization’s devices and assets.</div>
                            <div>
                                <Button icon labelPosition='right' onClick={() => dispatch({ type: 'OPEN_MODAL', dimmer: 'blurring' })} className='devicemanagementMainSectionButton'> 
                                LEARN MORE
                                    <Icon name='long arrow alternate right' />
                                </Button>

                                <Modal 
                                    className='deviceManagementModal'
                                    dimmer={dimmer}
                                    open={open}
                                    onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
                                >
                        
                                    <Modal.Content>
                                        <div className='devicemanagementModal'>
                                            <div className='devicemanagementModalContent'>
                                                <div className='devicemanagementModalHeader'>
                                                    <div className='devicemanagementModalHeaderTitle'> Device Management</div>
                                                    <div className='devicemanagementModalHeaderCloseIcon'>
                                                        <Icon name='window close outline' onClick={() => dispatch({ type: 'CLOSE_MODAL'})}></Icon>
                                                    </div>
                                                </div>
                                                    <div className='devicemanagementModalContentIcons'>
                                                        <div className='deviceManagement iconsRow'> 
                                                            <div><img className="headerIcon" src={iconDeviceManagement}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconIntune}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconWindows}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconAndroid}  alt="logo" /></div>
                                                            <div><img className="vendorIcon" src={iconApple}  alt="logo" /></div>
                                                        </div>
                                                    </div>
                                                <div className='devicemanagementModalContentText'>Manage and help ensure that devices are secure, up-to-date, and compliant with organizational policies, to protect the corporate network and data from unauthorized access</div>
                                            </div>
                                            <div className='devicemanagementModalFormComponent'>
                                                <ContactForm className="checkboxLabelBlack" />
                                            </div>
                                        </div>
                                    </Modal.Content>
                                </Modal>
                            </div>
                        </div>    
                </div>
            </div>
        </div>
    );
}

export default DeviceManagement;
